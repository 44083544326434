<template>
  <span>{{showAccess}}</span>
</template>

<script>

import state from "@/components/roleAccess/state";

export default {
  name: "accessFormat",
  props:{
    access:{
      type:String,
      default:"",
    }
  },
  computed:{
    showAccess(){
      let arr = [];
      if (this.access !== "")arr = this.access.split(",");
      if (arr.length <= 0)return  "";
      arr.forEach((item,key)=> {
        for (let stateKey in state) {
          if (item === state[stateKey].value){
            arr[key] = state[stateKey].label
          }
        }
      })
      return arr
    }
  }
}
</script>

<style scoped>

</style>