<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-table :data="list" border>
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="access_rights" label="访问限制" show-overflow-tooltip>
          <template #default="s">
            <access-format :access="s.row.access_rights"></access-format>
          </template>
        </el-table-column>
        <el-table-column prop="cate.title" label="所属分类"></el-table-column>
        <el-table-column prop="pic" label="缩略图">
          <template #default="s">
            <img v-if="s.row.pic" class="flex-def" :src="s.row.pic | tomedia" style="height: 2rem;width: 2rem;border-radius: .2rem"
                 alt="">
          </template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template #default="s">
            {{ s.row.created_at | date }}
          </template>
        </el-table-column>
        <el-table-column label="最后时间">
          <template #default="s">
            {{ s.row.updated_at | date }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #header>
            <el-button @click="$router.push({name:'card-article-edit'})" type="primary">添加文章</el-button>
          </template>
          <template #default="s">
            <el-button @click="$router.push({name:'card-article-edit',query:{id:s.row.id}})" type="primary">修改</el-button>
            <el-button @click="del(s.row)" type="danger">删除</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import AccessFormat from "@/components/roleAccess/accessFormat.vue";

export default {
  name: "list",
  components: {AccessFormat},
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    pageChange(e) {
      this.page = e;
      this.load();
    },
    del(item) {
      this.$u.api.card.article.del({id: item.id}).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
    load() {
      this.$u.api.card.article.page({
        page: this.page
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    }
  }
}
</script>

<style scoped>

</style>